import React, { useMemo } from "react";
import { Col, Typography } from "antd";
import PropTypes from "prop-types";

import * as classes from "./InternshipCoursePurchaseCard.module.scss";
import InfinityIcon from "../images/infinity.svg";
import certificateIon from "../images/certificate.svg";
import groupIcon from "../images/groups.svg";
import communityIcon from "../images/community.svg";
import chaptersIcon from "../images/chapters.svg";
import internshipIcon from "../images/internship.svg";
import levelIcon from "../images/level.svg";
import languageIcon from "../images/language.svg";
import projectIcon from "../images/projects.svg";
import { capitalizeFirstLetter } from "../../../utils/functions";
import InternshipBuyNowButton from "../InternshipBuyNowButton";
import IntenshipAddToCartButton from "../IntenshipAddToCartButton";

const { Title, Paragraph } = Typography;

const InternshipCoursePurchaseCardDesktop = (props) => {
  const { course, userSubscriptionStatus } = props;

  const courseDurationString = useMemo(
    () => `${course?.duration} ${course?.duration > 1 ? "Hours" : "Hour"}`,
    []
  );

  const chaptersCountString = useMemo(
    () =>
      `${course?.chapters.length} ${
        course?.chapters.length > 1 ? "Chapters" : "Chapter"
      }`,
    []
  );

  return (
    <Col className={classes.purchaseContainerDesktop}>
      <Col className={classes.videoContainer}>
        <iframe
          className={classes.videoIframe}
          src={
            course?.youtubeUrl
              ? course?.youtubeUrl
              : "https://player.vimeo.com/video/818680206?h=e311b4a50a&badge=0&autopause=0&player_id=0&app_id=58479"
          }
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen={true}
          id="iframe"
        />
        <script src="https://player.vimeo.com/api/player.js" />
      </Col>
      <Col className={classes.courseDetails}>
        <Title className={classes.price}>
          {course?.orignalPrice &&
            !isNaN(course?.orignalPrice) &&
            course?.orignalPrice - course?.price > 0 && (
              <span>&nbsp;₹{course?.orignalPrice}&nbsp;</span>
            )}
          ₹{course?.price}
        </Title>
        <Col
          className={classes.purchaseButtonContainer}
          id="skill-course-purchase-button-desktop"
        >
          <InternshipBuyNowButton
            customClass={classes.purchaseButton}
            customTextClass={classes.purchaseButtonText}
            course={course}
            userSubscriptionStatus={userSubscriptionStatus}
          />
        </Col>
        <IntenshipAddToCartButton
          customClass={classes.customButtonClass}
          customTextClass={classes.customButtonTextClass}
          course={course}
          userSubscriptionStatus={userSubscriptionStatus}
        />
        <Title className={classes.courseIncludesHeading}>
          This course includes:
        </Title>
        <Col className={classes.courseStats}>
          <Col className={classes.courseStatsTop}>
            <Col className={classes.courseStatsTopItem}>
              <img src={InfinityIcon} />
              <Paragraph className={classes.courseStatsTopText}>
                Lifelong Content Access
              </Paragraph>
            </Col>
            <Col className={classes.courseStatsTopItem}>
              <img src={certificateIon} />
              <Paragraph className={classes.courseStatsTopText}>
                {course?.nsdcCertified
                  ? "Unschool & NSDC Certification"
                  : "Unschool Certification"}
              </Paragraph>
            </Col>
            <Col className={classes.courseStatsTopItem}>
              <img src={projectIcon} />
              <Paragraph className={classes.courseStatsTopText}>
                2 Industry Projects
              </Paragraph>
            </Col>
            <Col className={classes.courseStatsTopItem}>
              <img src={internshipIcon} />
              <Paragraph className={classes.courseStatsTopText}>
                Case Studies & Internships
              </Paragraph>
            </Col>
            <Col className={classes.courseStatsTopItem}>
              <img src={groupIcon} />
              <Paragraph className={classes.courseStatsTopText}>
                Monthly Live Sessions
              </Paragraph>
            </Col>
          </Col>
          <Col className={classes.courseStatsBottom}>
            <Col className={classes.courseStatsBottomItem}>
              <img src={levelIcon} />
              <Paragraph className={classes.courseStatsBottomText}>
                {capitalizeFirstLetter(course?.level)} Level
              </Paragraph>
            </Col>
            <Col className={classes.courseStatsBottomItem}>
              <img src={languageIcon} />
              <Paragraph className={classes.courseStatsBottomText}>
                Language: English
              </Paragraph>
            </Col>
            <Col className={classes.courseStatsBottomItem}>
              <img src={chaptersIcon} />
              <Paragraph className={classes.courseStatsBottomText}>
                {chaptersCountString}, {courseDurationString}
              </Paragraph>
            </Col>
            <Col className={classes.courseStatsBottomItem}>
              <img src={communityIcon} />
              <Paragraph className={classes.courseStatsBottomText}>
                {course?.totalLearnersCount}+ Learners
              </Paragraph>
            </Col>
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

InternshipCoursePurchaseCardDesktop.propTypes = {
  course: PropTypes.object,
  userSubscriptionStatus: PropTypes.string,
};

export default InternshipCoursePurchaseCardDesktop;
