// extracted by mini-css-extract-plugin
export var container = "InternshipCourseChapters-module--container--a1eb9";
export var content = "InternshipCourseChapters-module--content--0f192";
export var curicullumItem = "InternshipCourseChapters-module--curicullumItem--4c4b4";
export var customButtonClass = "InternshipCourseChapters-module--customButtonClass--0ffea";
export var customButtonTextClass = "InternshipCourseChapters-module--customButtonTextClass--7bea8";
export var form = "InternshipCourseChapters-module--form--bfdf5";
export var formItem = "InternshipCourseChapters-module--formItem--8a93f";
export var formSubmissionSuccessText = "InternshipCourseChapters-module--formSubmissionSuccessText--97d0d";
export var heading = "InternshipCourseChapters-module--heading--a221b";
export var left = "InternshipCourseChapters-module--left--eaa2c";
export var lmsScrollbar = "InternshipCourseChapters-module--lms-scrollbar--06e2f";
export var minusToggleIcon = "InternshipCourseChapters-module--minusToggleIcon--48ff7";
export var plusToggleIcon = "InternshipCourseChapters-module--plusToggleIcon--dd752";
export var programForm = "InternshipCourseChapters-module--programForm--a99f0";
export var right = "InternshipCourseChapters-module--right--0ae31";
export var toggleIcon = "InternshipCourseChapters-module--toggleIcon--c3fbc";
export var wrapper = "InternshipCourseChapters-module--wrapper--5a5e7";