import { Col, Typography } from "antd";
import React from "react";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

import { CustomButton } from "../../adaptors/CustomButton";
import * as classes from "./NSDCRemoveoPopUpModal.module.scss";
import { variants } from "../../../utils/variants";
import { cartDetailsActions } from "../../../utils/redux/actions";
import { openToastMessage } from "../../../utils/redux/actions/helperActions";
import { ADD_OR_REMOVE_NSDC_CERTIFICATION_TO_COURSE } from "../../../../graphql/mutations/cart";
import { bindActionCreators } from "redux";

const { Text, Title } = Typography;

const NSDCRemoveoPopUpModalContent = (props) => {
  const { onCancel, cartItem } = props;

  const dispatch = useDispatch();

  const [updateNsdc] = useMutation(ADD_OR_REMOVE_NSDC_CERTIFICATION_TO_COURSE);

  const { updateCartDetails } = bindActionCreators(
    cartDetailsActions,
    dispatch
  );

  const onSubmit = async () => {
    try {
      const { data } = await updateNsdc({
        variables: {
          input: { cartItemUuid: cartItem.uuid, nsdcIncluded: false },
        },
      });
      if (data?.cartNsdcUpdate) {
        updateCartDetails(data?.cartNsdcUpdate);
        onCancel();
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) {
        openToastMessage({
          variant: variants.error,
        });
      }
    }
  };

  return (
    <Col className={classes.modalContentContainer}>
      <Title>
        <span>⚠️</span>Are you sure you want to checkout without the Govt.
        Approved NSDC certificate?
      </Title>
      <Col className={classes.nsdcDetails}>
        <Title className={classes.modalContent}>
          This NSDC certificate will help you:
        </Title>
        <p> - Get a Government approved certificate</p>
        <p> - Get competitive edge in securing Internship/ Job</p>
        <p> - Validate your expertise in the skill</p>
      </Col>
      <Text className={classes.notes}>
        🚫Note: "Once you opt out of NSDC certification, you cannot add it back"
      </Text>
      <Col className={classes.handleButtons}>
        <CustomButton
          title="Cancel"
          variant={variants.secondaryButton}
          customClass={classes.cancelCustomClass}
          customTextClass={classes.cancelCustomTextClass}
          onClick={onCancel}
        />
        <CustomButton
          title="Proceed Without Govt. NSDC Certificate"
          variant={variants.primaryButton}
          customClass={classes.removeCustomClass}
          customTextClass={classes.removeCustomTextClass}
          onClick={onSubmit}
        />
      </Col>
    </Col>
  );
};

export default NSDCRemoveoPopUpModalContent;
