import React, { useMemo } from "react";
import { Col, Typography } from "antd";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import * as classes from "./IntershipCourseHero.module.scss";
import ProfileAvatar from "../../ProfileAvatar";
import StarRatings from "../../StarRatings";
import swiggy from "../images/swiggy.svg";
import cocoCola from "../images/cocoCola.svg";
import elekta from "../images/elekta.svg";
import intercom from "../images/intercom.svg";
import urbanClap from "../images/urbanClap.svg";
import InternshipCoursePurchaseCardDesktop from "../IntershipCoursePurchaseCard/InternshipCoursePurchaseCardDesktop";
import InternshipCoursePurchaseCardMobile from "../IntershipCoursePurchaseCard/InternshipCoursePurchaseCardMobile";
import { HOME_LANDING_PAGE_FILES_PATH } from "../../../utils/localization";

const { Title, Paragraph } = Typography;

const companies = [swiggy, cocoCola, elekta, intercom, urbanClap];

const IntershipCourseHero = (props) => {
  const {
    course,
    coach,
    userSubscriptionStatus,
    courseDurationString,
    chaptersCountString,
  } = props;

  const getCourseRating = useMemo((course) => {
    if (course?.category?.slug === "software-and-technology") {
      return {
        reviewers: "2500+",
        rating: 4.1,
      };
    }
    return {
      reviewers: "2000+",
      rating: 4.2,
    };
  }, []);

  return (
    <Col className={classes.courseHeroContainer}>
      <Col className={classes.wrapper}>
        <Col className={classes.courseHeroContentLeft}>
          <Col className={classes.breadcrumbs}>
            <a href="/">Browse</a>
            <Link to={`/categories/${course?.category?.slug}`}>
              {course?.category?.name}
            </Link>
            <span>{course?.title}</span>
          </Col>
          <Title className={classes.heroTitle}>{course?.title}</Title>
          <Col className={classes.courseRatingMobile}>
            <StarRatings
              rating={course?.averageRating}
              reviewers={course?.totalReviewsCount}
              spacing={4}
            />
          </Col>
          <Col className={classes.instructorProfile}>
            <ProfileAvatar
              size={32}
              customClass={classes.instructorPhoto}
              profilePicture={{
                url: coach?.profilePicture?.url,
              }}
              firstName={coach?.firstName}
              lastName={coach?.firstLast}
              alt={"Image"}
            />
            <Paragraph className={classes?.instructorName}>
              {coach?.firstName}
            </Paragraph>
          </Col>
          <Paragraph className={classes.courseDescription}>
            {course?.shortDescription}
          </Paragraph>
          <Col className={classes.courseRatingDesktop}>
            <StarRatings
              rating={getCourseRating.rating}
              reviewers={getCourseRating.reviewers}
              spacing={4}
            />
          </Col>
          {course?.nsdcCertified ? (
            <Col className={classes.courseApprovals}>
              <h1>This Course Is Approved By</h1>
              <img
                src={`${HOME_LANDING_PAGE_FILES_PATH}/courseApprovals.png`}
              />
            </Col>
          ) : (
            <Col className={classes.companiesContainer}>
              <Title className={classes.companiesHeading}>
                Learner’s got opportunities at
              </Title>
              <Col className={classes.companies}>
                {companies.map((company, index) => (
                  <img
                    key={index}
                    src={company}
                    alt="image"
                    className={classes.companyLogo}
                  />
                ))}
              </Col>
            </Col>
          )}
          <Col className={classes.nsdc}></Col>
          <Col className={classes.coursePurchaseContainerMobile}>
            <InternshipCoursePurchaseCardMobile
              course={course}
              userSubscriptionStatus={userSubscriptionStatus}
              chaptersCountString={chaptersCountString}
              courseDurationString={courseDurationString}
            />
          </Col>
        </Col>
        <Col className={classes.courseHeroContentRight}>
          <Col className={classes.coursePurchaseContainerDesktop}>
            <InternshipCoursePurchaseCardDesktop
              course={course}
              userSubscriptionStatus={userSubscriptionStatus}
              chaptersCountString={chaptersCountString}
              courseDurationString={courseDurationString}
            />
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

IntershipCourseHero.propTypes = {
  course: PropTypes.object,
  coach: PropTypes.object,
  userSubscriptionStatus: PropTypes.string,
  courseDurationString: PropTypes.string,
  chaptersCountString: PropTypes.string,
};

export default IntershipCourseHero;
