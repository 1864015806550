import { Col, Typography } from "antd";
import React from "react";

import * as classes from "./InternshipCoursePurchaseSuccessModalContent.module.scss";
import SuccessLogo from "../images/creative-tick.svg";
import {
  HOME_LANDING_PAGE_FILES_PATH,
  IMAGE_PLACEHOLDER,
  LMS_PLATFORM_OUT_LINK,
} from "../../../utils/localization";
import { useSelector } from "react-redux";

const { Text, Title, Paragraph } = Typography;

const InternshipCoursePurchaseSuccessModalContent = () => {
  const { userCourses } = useSelector((state) => {
    return state;
  });

  const bill = userCourses?.coursePurchaseBill;
  const cartItem = bill?.cartItems[0];

  return (
    <Col className={classes.successDetails}>
      <Col className={classes.successDetailsInner}>
        <img src={SuccessLogo} alt="Success Logo" />
        <h3>Course Purchase Successful!</h3>
        <p>
          Thank you for signing up with Unschool. You can access your courses
          through the learning portal.
        </p>
        <a href={LMS_PLATFORM_OUT_LINK}>
          <button className={classes.portalBtn}>GO TO LEARNING PORTAL</button>
        </a>
      </Col>
      <Col className={classes.purchaseDetails}>
        <Col className={classes.bill}>
          <Title className={classes.billTitle}>Bill Details</Title>
          <Col className={classes.billItem}>
            <Text className={classes.billContent}>Subtotal</Text>
            <Text className={classes.billAmount}>₹ {(bill?.subTotalAmount + bill?.nsdcAmount).toFixed(2)}</Text>
          </Col>
          <Col className={classes.billItem}>
            <Text className={classes.billContent}>Total Discount</Text>
            <Text className={classes.billAmount}>₹ {bill?.discountAmount}</Text>
          </Col>
          <Col className={classes.billItem}>
            <Text className={classes.billContent}>GST 18%</Text>
            <Text className={classes.billAmount}>₹ {bill?.taxAmount}</Text>
          </Col>
          <Col className={classes.billTotal}>
            <Text className={classes.totalContent}>Items total</Text>
            <Text className={classes.totalAmount}>₹ {bill?.totalAmount}</Text>
          </Col>
          {bill.coupon && (
            <Col className={classes.billItem}>
              <Text className={classes.billContent}>Coupon Used</Text>
              <Text className={classes.billAmount}>{bill?.coupon}</Text>
            </Col>
          )}
        </Col>
        <Col className={classes.planDetails}>
          <Title>Course Purchase Details</Title>
          <Col className={classes.planItem}>
            <img
              loading="lazy"
              src={cartItem.itemable?.coverImage?.url || IMAGE_PLACEHOLDER}
            />
            <Col className={classes.itemDetail}>
              <Paragraph className={classes.title}>
                {cartItem?.itemable?.title}
              </Paragraph>
              <Paragraph className={classes.price}>
                ₹{cartItem?.itemable?.price}
              </Paragraph>
            </Col>
          </Col>
          {cartItem?.nsdcIncluded && (
            <Col className={classes.planItem}>
              <img
                loading="lazy"
                src={`${HOME_LANDING_PAGE_FILES_PATH}/nsdc-certificate.png`}
              />
              <Col className={classes.itemDetail}>
                <Paragraph className={classes.title}>
                  NSDC Certificate
                </Paragraph>
                <Paragraph className={classes.price}>
                  ₹{cartItem?.nsdcPrice}
                </Paragraph>
              </Col>
            </Col>
          )}
        </Col>
      </Col>
    </Col>
  );
};

export default InternshipCoursePurchaseSuccessModalContent;
