import { Col } from "antd";
import React from "react";

import * as classes from "./IntershipCourseFor.module.scss";
import { INTERNSHIP_COURSE_PAGE_FILES_PATH } from "../../../utils/localization";

const items = [
  {
    id: 1,
    image: `${INTERNSHIP_COURSE_PAGE_FILES_PATH}/education.svg`,
    title: "Graduates",
  },
  {
    id: 3,
    image: `${INTERNSHIP_COURSE_PAGE_FILES_PATH}/freelancer.svg`,
    title: "Freelancers",
  },
  {
    id: 4,
    image: `${INTERNSHIP_COURSE_PAGE_FILES_PATH}/engineer.svg`,
    title: "Working Professionals",
  },
];

const IntershipCourseFor = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <h1>Who Is This Program For?</h1>
        <Col className={classes.items}>
          {items.map((item) => {
            return (
              <Col className={classes.item}>
                <img src={item.image} />
                <p>{item.title}</p>
              </Col>
            );
          })}
        </Col>
      </Col>
    </Col>
  );
};

export default IntershipCourseFor;
