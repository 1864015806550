import { Col } from "antd";
import React, { useRef } from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import * as classes from "./InternshipCourseChapters.module.scss";
import { useState } from "react";

const ChapterCard = (props) => {
  const { chapter } = props;

  const [active, setActive] = useState(false);
  const ref = useRef();

  const handleClose = () => {
    setActive(false);
  };

  const handleOpen = () => {
    setActive(true);
  };

  return (
    <Col className={classes.curicullumItem} key={chapter.id}>
      <h3>{chapter.title}</h3>
      <Col
        ref={ref}
        style={{
          height: active ? ref.current.scrollHeight : "0",
        }}
      >
        <p>{chapter?.description}</p>
      </Col>
      <Col className={classes.toggleIcon}>
        {active ? (
          <MinusOutlined
            className={classes.minusToggleIcon}
            onClick={handleClose}
          />
        ) : (
          <PlusOutlined
            className={classes.plusToggleIcon}
            onClick={handleOpen}
          />
        )}
      </Col>
    </Col>
  );
};

export default ChapterCard;
