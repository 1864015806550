import { useMutation } from "@apollo/client";
import { Button, Col, Form, Typography } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "antd/lib/form/Form";
import Zoom from "react-medium-image-zoom";

import * as classes from "./BuyNowModalContent.module.scss";
import {
  ADD_OR_REMOVE_NSDC_CERTIFICATION_TO_COURSE,
  APPLY_COUPON,
  CREATE_RAZORPAY_ORDER,
  REMOVE_COUPON,
  RESET_CART,
  VERIFY_PAYMENT,
} from "../../../../graphql/mutations/cart";
import {
  cartDetailsActions,
  helperActions,
  modalActions,
} from "../../../utils/redux/actions";
import { variants } from "../../../utils/variants";
import CustomInput from "../../adaptors/CustomInput";
import { CustomButton } from "../../adaptors/CustomButton";
import FormErrorMessage from "../../FormErrorMessage";
import { gqlErrors } from "../../../../graphql/errors";
import { logger } from "../../../helpers/logger";
import voucherIcon from "../images/voucher.svg";
import {
  IMAGE_PLACEHOLDER,
  INTERNSHIP_COURSE_PAGE_FILES_PATH,
  SENIOR_COACH,
} from "../../../utils/localization";
import ProfileAvatar from "../../ProfileAvatar";
import { userCoursesActions } from "../../../utils/redux/actions";
import { CustomModal } from "../../CustomModal";
import NSDCModalContent from "../NSDCModalContent";
import { CustomIcon } from "../../adaptors/CustomIcon";
import { fas } from "../../../utils/icons";
import NSDCRemoveoPopUpModalContent from "../NSDCRemoveoPopUpModalContent";
import classNames from "classnames";
import { isMobile } from "../../../utils/helpers/global";

const { Text, Title } = Typography;

const BuyNowModalContent = (props) => {
  const { course } = props;

  const { cartDetails, user } = useSelector((state) => {
    return state;
  });

  const cartItem = cartDetails?.cartItems[0] ?? {};

  const [form] = useForm();
  const { setFields } = form;
  const [checkoutButtonLoading, setCheckoutButtonLoading] = useState(false);

  const dispatch = useDispatch();

  const {
    openAuthModal,
    closeInternshipCoursePurchaseModal,
    openInternshipCoursePurchaseSuccessModal,
  } = bindActionCreators(modalActions, dispatch);
  const { openToastMessage } = bindActionCreators(helperActions, dispatch);
  const { updateCartDetails, resetCartDetails } = bindActionCreators(
    cartDetailsActions,
    dispatch
  );
  const { setUserCoursePurchaseBill } = bindActionCreators(
    userCoursesActions,
    dispatch
  );

  const [applyCoupon] = useMutation(APPLY_COUPON);
  const [removeCoupon] = useMutation(REMOVE_COUPON);
  const [createRazorPayOrder] = useMutation(CREATE_RAZORPAY_ORDER);
  const [verifyRazorPayPayment] = useMutation(VERIFY_PAYMENT);
  const [updateNsdc] = useMutation(ADD_OR_REMOVE_NSDC_CERTIFICATION_TO_COURSE);

  const [nsdcModalOpen, setNsdcModalOpen] = useState(false);
  const [openNsdcRemovePopup, setNsdcRemovePopup] = useState(false);

  const coach = useMemo(
    () =>
      course?.coaches.filter((coach) => coach?.userType === SENIOR_COACH)[0],
    []
  );

  const handleApplyCoupon = async ({ coupon, cartUuId }) => {
    try {
      const { data } = await applyCoupon({
        variables: { couponCode: coupon, cartUuid: cartUuId },
      });
      if (data?.couponApply) {
        const couponAppliedCartDetails = data.couponApply;
        updateCartDetails(couponAppliedCartDetails);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (
        gqlError?.message === gqlErrors.messages.couponCodeInvalid ||
        gqlError?.message === gqlErrors.messages.couponCodeAlreadyPresent ||
        gqlError?.message === gqlErrors.messages.cartNotFound
      ) {
        setFields([
          {
            name: "coupon",
            errors: [
              <FormErrorMessage
                hideFormHelperIcon
                key="coupon-error-message"
                type="coupon"
              />,
            ],
          },
        ]);
      } else {
        logger.print(gqlError);
      }
    }
  };

  const handleRemoveCoupon = async ({ cartUuId }) => {
    try {
      const { data } = await removeCoupon({
        variables: { cartUuid: cartUuId },
      });
      if (data?.couponRemove) {
        updateCartDetails(data?.couponRemove);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) {
        logger.warn(gqlError);
      }
    }
  };

  const onFinish = (values) => {
    let { coupon } = values;
    handleApplyCoupon({ coupon: coupon, cartUuId: cartDetails?.uuid });
  };

  const handleRazorPayCheckout = (prefill, amountDue, id) => {
    let paymentOptions = {
      key: process.env.GATSBY_RAZORPAY_API_KEY,
      name: "Unschool",
      currency: "INR",
      order_id: id,
      amount: amountDue,
      handler: async function (response) {
        if (
          response?.razorpay_payment_id &&
          response?.razorpay_order_id &&
          response?.razorpay_signature
        ) {
          try {
            const { data } = await verifyRazorPayPayment({
              variables: {
                cartUuid: cartDetails?.uuid,
                rzpOrderId: id,
              },
            });
            if (data?.razorpayVerifyPayment) {
              setUserCoursePurchaseBill(data?.razorpayVerifyPayment);
            }
            closeInternshipCoursePurchaseModal();
            openInternshipCoursePurchaseSuccessModal();
            resetCartDetails();
          } catch (error) {
            const gqlError = error.graphQLErrors[0];
            if (gqlError) {
              logger.warn(gqlError);
            }
          }
        }
      },
      prefill,
      theme: {
        color: "#F37254",
      },
      modal: {
        animation: true,
        ondismiss: () => {
          setCheckoutButtonLoading(false);
        },
      },
    };
    let rzp1 = new window.Razorpay(paymentOptions);
    rzp1.open();
  };

  const handleCreateRazorPayOrder = async (prefill, cartDetails) => {
    try {
      const { data } = await createRazorPayOrder({
        variables: {
          cartUuid: cartDetails?.uuid,
        },
      });
      if (data?.razorpayOrderCreate) {
        const { amountDue, id } = data.razorpayOrderCreate;
        handleRazorPayCheckout(prefill, amountDue, id);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) {
        openToastMessage({
          variant: variants.error,
        });
      }
    }
  };

  const handleCheckout = (user) => {
    const isUserSignedIn = user?.isUserLoggedIn;
    if (!isUserSignedIn) {
      openAuthModal();
    } else {
      setCheckoutButtonLoading(true);
      const userDetails = user?.userDetails;
      handleCreateRazorPayOrder(
        {
          name: `${userDetails.firstName} ${userDetails.lastName}`,
          email: userDetails.email,
          contact: userDetails.mobileNumber,
        },
        cartDetails
      );
    }
  };

  const handleRemoveNsdc = () => {
    setNsdcRemovePopup(true);
  };

  const handleAddNsdc = async () => {
    try {
      const { data } = await updateNsdc({
        variables: {
          input: { cartItemUuid: cartItem.uuid, nsdcIncluded: true },
        },
      });
      if (data?.cartNsdcUpdate) {
        updateCartDetails(data?.cartNsdcUpdate);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) {
        openToastMessage({
          variant: variants.error,
        });
      }
    }
  };

  return (
    <Col className={classes.container}>
      <Col className={classes.courseDetails}>
        <Col>
          <Zoom>
            <img
              className={classes.courseImage}
              src={course?.coverImage?.url || IMAGE_PLACEHOLDER}
            />
          </Zoom>
          <Col>
            <h3>{course?.title}</h3>
            <Col className={classes.instructorProfile}>
              <ProfileAvatar
                size={32}
                customClass={classes.instructorPhoto}
                profilePicture={{
                  url: coach?.profilePicture?.url,
                }}
                firstName={coach?.firstName}
                lastName={coach?.firstLast}
                alt={"Image"}
              />
              <p>{coach?.firstName}</p>
            </Col>
          </Col>
        </Col>
        <Title className={classes.coursePrice}>
          {course?.orignalPrice &&
            !isNaN(course?.orignalPrice) &&
            course?.orignalPrice - course?.price > 0 && (
              <span>&nbsp;₹{course?.orignalPrice}&nbsp;</span>
            )}
          ₹{course?.price}
        </Title>
      </Col>
      {course?.nsdcCertified && (
        <Col className={classes.nsdcCertificationAddOn}>
          <Col className={classes.nsdcCertificationAddOnItem}>
            <Col className={classes.left}>
              <Zoom>
                <img
                  className={classes.nsdcCertificateImage}
                  src={`${INTERNSHIP_COURSE_PAGE_FILES_PATH}/nsdc-certificate.png`}
                  alt="NSDC Certificate"
                />
              </Zoom>
              <Col>
                <Title>NSDC Certification</Title>
                <Button
                  onClick={() => {
                    setNsdcModalOpen(true);
                  }}
                >
                  Why This ?
                </Button>
              </Col>
            </Col>
            {cartItem?.nsdcIncluded ? (
              <Col className={classes.right}>
                <Title>
                  {!isNaN(cartItem?.nsdcOrignalPriceCart) &&
                    cartItem?.nsdcOrignalPriceCart - cartItem?.nsdcPrice >
                      0 && (
                      <span>&nbsp;₹{cartItem?.nsdcOrignalPriceCart}&nbsp;</span>
                    )}
                  ₹{cartItem?.nsdcPrice}
                </Title>
                <Button onClick={handleRemoveNsdc}>
                  <CustomIcon
                    icon={fas.faTrash}
                    customClass={classes.deleteIcon}
                  />
                </Button>
              </Col>
            ) : (
              <Col className={classes.right}>
                <Title>
                  {!isNaN(cartItem?.nsdcOrignalPriceCart) &&
                    cartItem?.nsdcOrignalPriceCart - cartItem?.nsdcPrice >
                      0 && (
                      <span>&nbsp;₹{cartItem?.nsdcOrignalPriceCart}&nbsp;</span>
                    )}
                  ₹{cartItem?.nsdcPrice}
                </Title>
                <CustomButton
                  variant={variants.primaryButton}
                  htmlType="button"
                  title="Add"
                  onClick={handleAddNsdc}
                  customClass={classes.nsdcAddButtonClass}
                  customTextClass={classes.nsdcAddButtonClassTextClass}
                />
              </Col>
            )}
          </Col>
        </Col>
      )}
      <Col className={classes.billDetails}>
        <Title className={classes.billTitle}>Bill Details</Title>
        <Col className={classes.billItem}>
          <Text className={classes.billContent}>Subtotal</Text>
          <Text className={classes.billAmount}>
            ₹{(cartDetails?.subTotalAmount + cartDetails?.nsdcAmount).toFixed(2)}
          </Text>
        </Col>
        <Col className={classes.billItem}>
          <Text className={classes.billContent}>Total Discount</Text>
          <Text className={classes.billAmount}>
            ₹{cartDetails?.discountAmount}
          </Text>
        </Col>
        <Col className={classes.billItem}>
          <Text className={classes.billContent}>GST 18%</Text>
          <Text className={classes.billAmount}>₹{cartDetails?.taxAmount}</Text>
        </Col>
        <Col className={classes.billTotal}>
          <Text className={classes.totalContent}>Items total</Text>
          <Text className={classes.totalAmount}>
            ₹{cartDetails?.totalAmount}
          </Text>
        </Col>
      </Col>
      <Col className={classes.couponContainer}>
        {cartDetails?.coupon ? (
          <Col className={classes.appliedCoupon}>
            <img src={voucherIcon} className={classes.appliedCouponIcon} />
            <Text className={classes.appliedCouponName}>
              {cartDetails?.coupon?.code}
            </Text>
            <Text className={classes.appliedCouponText}>applied</Text>
            <CustomButton
              iconButton
              size="small"
              variant={variants.ghostButton}
              icon="fas fa-trash-alt"
              onClick={() =>
                handleRemoveCoupon({ cartUuId: cartDetails?.uuid })
              }
            />
          </Col>
        ) : (
          <Form
            form={form}
            name="basic"
            layout="inline"
            onFinish={onFinish}
            autoComplete="off"
            className={classes.couponForm}
          >
            <Form.Item
              className={classes.formItemLeft}
              name="coupon"
              rules={[
                {
                  required: true,
                  message: <FormErrorMessage hideFormHelperIcon />,
                },
              ]}
            >
              <CustomInput
                formItem
                variant={variants.textInput}
                placeholder="Coupon code"
                customClass={classes.couponInput}
              />
            </Form.Item>
            <Form.Item className={classes.formItemRight}>
              <CustomButton
                customClass={classes.applyCouponBtn}
                customTextClass={classes.applyCouponBtnTextClass}
                variant={variants.primaryButton}
                htmlType="submit"
                title="Apply"
              />
            </Form.Item>
          </Form>
        )}
      </Col>
      <Col className={classes.checkoutButtonConatiner}>
        <CustomButton
          customClass={classes.subscribeButton}
          customTextClass={classes.subscribeButtonTextClass}
          variant={variants.primaryButton}
          htmlType="button"
          title={
            cartItem?.nsdcIncluded
              ? "Check Out"
              : "Check Out Without NSDC Certificate"
          }
          loading={checkoutButtonLoading}
          onClick={() => {
            handleCheckout(user);
          }}
        />
      </Col>
      <CustomModal
        visible={nsdcModalOpen}
        onCancel={() => {
          setNsdcModalOpen(false);
        }}
        centered={true}
        width="auto"
        maskStyle={{ background: "rgba(0, 0, 0, 0.45)" }}
        title="NSDC Certificate Details"
        bodyStyle={{
          padding: "0 20px 20px 20px",
        }}
      >
        <NSDCModalContent />
      </CustomModal>
      <CustomModal
        visible={openNsdcRemovePopup}
        onCancel={() => {
          setNsdcRemovePopup(false);
        }}
        centered={true}
        width="auto"
        maskStyle={{ background: "rgba(0, 0, 0, 0.45)" }}
        title=""
        bodyStyle={
          isMobile()
            ? {
                padding: "60px 15px 15px 15px",
              }
            : {
                padding: "50px 60px 30px 30px",
              }
        }
      >
        <NSDCRemoveoPopUpModalContent
          cartItem={cartItem}
          onCancel={() => {
            setNsdcRemovePopup(false);
          }}
        />
      </CustomModal>
    </Col>
  );
};

export default BuyNowModalContent;
