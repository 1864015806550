// extracted by mini-css-extract-plugin
export var accordianHeader = "InternshipCourseFaq-module--accordianHeader--084ed";
export var accordianItem = "InternshipCourseFaq-module--accordianItem--38bde";
export var accordianText = "InternshipCourseFaq-module--accordianText--e8feb";
export var contactUnschool = "InternshipCourseFaq-module--contactUnschool--b88b8";
export var courseFaqContainer = "InternshipCourseFaq-module--courseFaqContainer--09384";
export var courseFaqContent = "InternshipCourseFaq-module--courseFaqContent--36cdc";
export var courseFaqContentLeft = "InternshipCourseFaq-module--courseFaqContentLeft--a75dd";
export var courseFaqContentRight = "InternshipCourseFaq-module--courseFaqContentRight--f7b4d";
export var faqImageContainer = "InternshipCourseFaq-module--faqImageContainer--0d140";
export var headingContainer = "InternshipCourseFaq-module--headingContainer--217a9";
export var headingDesktop = "InternshipCourseFaq-module--headingDesktop--31fcc";
export var headingMobile = "InternshipCourseFaq-module--headingMobile--6ddc4";
export var iconClassName = "InternshipCourseFaq-module--iconClassName--c95b3";
export var lmsScrollbar = "InternshipCourseFaq-module--lms-scrollbar--1ebde";