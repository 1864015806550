import { gql } from "@apollo/client";

export const CREATE_INTERNSHIP_LEAD = gql`
  mutation createInternshipLead(
    $fullName: String!
    $email: String!
    $phoneNumber: String!
    $courseId: Int!
  ) {
    createLead(
      input: {
        fullName: $fullName
        email: $email
        phoneNumber: $phoneNumber
        courseId: $courseId
      }
    ) {
      id
    }
  }
`;
