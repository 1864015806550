// extracted by mini-css-extract-plugin
export var appliedCoupon = "BuyNowModalContent-module--appliedCoupon--90f34";
export var appliedCouponIcon = "BuyNowModalContent-module--appliedCouponIcon--a03cf";
export var appliedCouponName = "BuyNowModalContent-module--appliedCouponName--3161d";
export var appliedCouponText = "BuyNowModalContent-module--appliedCouponText--666fc";
export var applyCouponBtn = "BuyNowModalContent-module--applyCouponBtn--0fc54";
export var applyCouponBtnTextClass = "BuyNowModalContent-module--applyCouponBtnTextClass--33bf2";
export var billAmount = "BuyNowModalContent-module--billAmount--11b63";
export var billContent = "BuyNowModalContent-module--billContent--012cc";
export var billDetails = "BuyNowModalContent-module--billDetails--d4495";
export var billItem = "BuyNowModalContent-module--billItem--76416";
export var billTitle = "BuyNowModalContent-module--billTitle--6701b";
export var billTotal = "BuyNowModalContent-module--billTotal--83d8a";
export var checkoutButtonConatiner = "BuyNowModalContent-module--checkoutButtonConatiner--6783d";
export var classesCloseIcon = "BuyNowModalContent-module--classesCloseIcon--e6efa";
export var container = "BuyNowModalContent-module--container--37872";
export var couponContainer = "BuyNowModalContent-module--couponContainer--0ef52";
export var couponForm = "BuyNowModalContent-module--couponForm--b9e4f";
export var couponInput = "BuyNowModalContent-module--couponInput--ca4b5";
export var courseDetails = "BuyNowModalContent-module--courseDetails--f2871";
export var courseImage = "BuyNowModalContent-module--courseImage--d230a";
export var coursePrice = "BuyNowModalContent-module--coursePrice--38f7b";
export var deleteIcon = "BuyNowModalContent-module--deleteIcon--505cc";
export var formItemLeft = "BuyNowModalContent-module--formItemLeft--99565";
export var formItemRight = "BuyNowModalContent-module--formItemRight--30802";
export var infoCircle = "BuyNowModalContent-module--infoCircle--555ba";
export var instructorPhoto = "BuyNowModalContent-module--instructorPhoto--a1277";
export var instructorProfile = "BuyNowModalContent-module--instructorProfile--37bbf";
export var left = "BuyNowModalContent-module--left--74eec";
export var lmsScrollbar = "BuyNowModalContent-module--lms-scrollbar--d40aa";
export var nsdcAddButtonClass = "BuyNowModalContent-module--nsdcAddButtonClass--2e93b";
export var nsdcAddButtonClassTextClass = "BuyNowModalContent-module--nsdcAddButtonClassTextClass--a142f";
export var nsdcCertificateImage = "BuyNowModalContent-module--nsdcCertificateImage--abd71";
export var nsdcCertificationAddOn = "BuyNowModalContent-module--nsdcCertificationAddOn--cb44a";
export var nsdcCertificationAddOnItem = "BuyNowModalContent-module--nsdcCertificationAddOnItem--bee01";
export var right = "BuyNowModalContent-module--right--71e1f";
export var subscribeButton = "BuyNowModalContent-module--subscribeButton--5ce9f";
export var subscribeButtonTextClass = "BuyNowModalContent-module--subscribeButtonTextClass--074b1";
export var totalAmount = "BuyNowModalContent-module--totalAmount--bc2a4";
export var totalContent = "BuyNowModalContent-module--totalContent--f25cd";