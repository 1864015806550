// extracted by mini-css-extract-plugin
export var coachContainer = "InternshipCourseCoach-module--coachContainer--c355e";
export var coachContent = "InternshipCourseCoach-module--coachContent--6f674";
export var coachDetails = "InternshipCourseCoach-module--coachDetails--0573a";
export var coachDetailsDesktop = "InternshipCourseCoach-module--coachDetailsDesktop--0fb75";
export var coachDetailsMobile = "InternshipCourseCoach-module--coachDetailsMobile--4515e";
export var coachImage = "InternshipCourseCoach-module--coachImage--231bd";
export var coachIntro = "InternshipCourseCoach-module--coachIntro--a9199";
export var coachName = "InternshipCourseCoach-module--coachName--71298";
export var courseStats = "InternshipCourseCoach-module--courseStats--2e07e";
export var heading = "InternshipCourseCoach-module--heading--8abb4";
export var lmsScrollbar = "InternshipCourseCoach-module--lms-scrollbar--b712f";
export var statsIcon = "InternshipCourseCoach-module--statsIcon--91140";
export var statsList = "InternshipCourseCoach-module--statsList--ef3db";
export var wrapper = "InternshipCourseCoach-module--wrapper--00916";