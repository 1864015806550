import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import "react-medium-image-zoom/dist/styles.css";

import LayoutWithOutPadding from "../../components/LayoutWithOutPadding";
import { SENIOR_COACH } from "../../utils/localization";
import IntershipCourseHero from "../../components/IntershipProgam1.0/IntershipCourseHero";
import InternshipCourseDetails from "../../components/IntershipProgam1.0/InternshipCourseDetails";
import InternshipCourseCoach from "../../components/IntershipProgam1.0/InternshipCourseCoach";
import InternshipCourseCertificate from "../../components/IntershipProgam1.0/InternshipCourseCertificate";
import InternshipCourseFaq from "../../components/IntershipProgam1.0/InternshipCourseFaq";
import InternshipCourseBottomPurchaseCard from "../../components/IntershipProgam1.0/IntershipCourseBottomPurchaseCard";
import InternshipCourseReviews from "../../components/IntershipProgam1.0/InternshipCourseReviews";
import IntershipCourseForTwo from "../../components/IntershipProgam1.0/IntershipCourseForTwo";
import InternshipCourseChapters from "../../components/IntershipProgam1.0/InternshipCourseChapters";
import internshipCourseSeoDetails from "../../constants/internshipCourseSeoDetails";
import { CustomModal } from "../../components/CustomModal";
import InternshipCoursePurchaseSuccessModalContent from "../../components/IntershipProgam1.0/InternshipCoursePurchaseSuccessModalContent";
import Seo from "../../components/IntershipProgam1.0/Seo";
import NSDC from "../../components/IntershipProgam1.0/NSDC";
import { isSSR } from "../../helpers/global";

const InternshipCourse = (props) => {
  const [showBottomPurchaseCard, setShowBottomPurchaseCard] = useState(false);

  const { pageContext } = props;
  const { course } = pageContext;

  const { userCourses, modals } = useSelector((state) => state);

  const coach = useMemo(
    () =>
      course?.coaches.filter((coach) => coach?.userType === SENIOR_COACH)[0],
    []
  );

  const userSubscriptionStatus = useMemo(() => {
    const findCourse = userCourses?.courses?.find(
      (courseItem) => courseItem.id === course.id
    );
    return findCourse ? true : false;
  }, [userCourses.courses]);

  const chaptersCountString = useMemo(
    () =>
      `${course?.chapters?.length} ${
        course?.chapters?.length > 1 ? "Chapters" : "Chapter"
      }`,
    [course]
  );

  const courseDurationString = useMemo(
    () => `${course?.duration} ${course?.duration > 1 ? "Hours" : "Hour"}`,
    [course]
  );

  useEffect(() => {
    const listenScrollEvent = () => {
      const width = window.innerWidth;
      let elemTop = document
        ?.getElementById(
          width <= 991
            ? "skill-course-purchase-button-mobile"
            : "skill-course-purchase-button-desktop"
        )
        ?.getBoundingClientRect().top;
      if (elemTop < 0) {
        setShowBottomPurchaseCard(true);
      } else {
        setShowBottomPurchaseCard(false);
      }
    };

    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <LayoutWithOutPadding>
      <IntershipCourseHero
        course={course}
        coach={coach}
        userSubscriptionStatus={userSubscriptionStatus}
        courseDurationString={courseDurationString}
        chaptersCountString={chaptersCountString}
      />
      <InternshipCourseDetails course={course} />
      {course?.nsdcCertified && <NSDC />}
      <InternshipCourseCoach coach={coach} />
      <IntershipCourseForTwo />
      <InternshipCourseChapters course={course} />
      <InternshipCourseReviews />
      <InternshipCourseCertificate course={course} />
      <InternshipCourseFaq course={course} />
      {showBottomPurchaseCard && (
        <InternshipCourseBottomPurchaseCard
          course={course}
          userSubscriptionStatus={userSubscriptionStatus}
        />
      )}
      <CustomModal
        visible={modals.internshipCoursePurchaseSuccessModal}
        onCancel={() => {
          window.location.reload();
        }}
        width="fit-content"
      >
        <InternshipCoursePurchaseSuccessModalContent />
      </CustomModal>
    </LayoutWithOutPadding>
  );
};

export function Head(props) {
  const { pageContext } = props;
  const { course } = pageContext;
  const seoTags = internshipCourseSeoDetails[course?.slug];
  const pageTitle = seoTags?.metaTags?.titleMetaTagContent ?? course?.title;
  return (
    <Seo
      title={pageTitle}
      description={seoTags?.metaTags?.descriptionMetaTagContent}
      keywords={seoTags?.metaTags?.keywordsMetaTagContent}
      robots={seoTags?.metaTags?.robotsMetaTagContent}
      httpEquiv={seoTags?.metaTags?.httpEquivMetaTagContent}
      language={seoTags?.metaTags?.languageMetaTagContent}
      revisitAfter={seoTags?.metaTags?.revisitAfterMetaTagContent}
      author={seoTags?.metaTags?.authorMetaTagContent}
      schemaMarkup={seoTags?.scriptTags}
    />
  );
}

export default InternshipCourse;
