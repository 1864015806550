import React, { useRef } from "react";
import { Col } from "antd";
import Slider from "react-slick";

import * as classes from "./InternshipCourseReviews.module.scss";

import ReviewCard from "./ReviewCard";
import { reviewes } from "../data";
import { ReactSlickController } from "../../adaptors/ReactSlickController";
import { useState } from "react";
import { useEffect } from "react";

const InternshipCourseReviews = () => {
  const ref = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const [buttonsController, setButtonController] = useState({
    next: () => {},
    prev: () => {},
    slickGoTo: () => {},
  });

  const settings = {
    dots: false,
    arrows: false,
    centerMode: true,
    slidesToShow: 1,
    speed: 500,
    rows: 1,
  };

  useEffect(() => {
    const slickController = { ...ref?.current };
    setButtonController((state) => ({
      ...state,
      next: slickController?.slickNext,
      prev: slickController?.slickPrev,
      slickGoTo: slickController?.slickGoTo,
    }));
  }, [ref]);

  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <h1>
          Hear it from our <span>learners</span>
        </h1>
        <Col className={classes.reviewsDesktop}>
          {reviewes.map((review) => {
            return <ReviewCard review={review} />;
          })}
        </Col>
        <Col className={classes.reviewsTablet}>
          <Slider ref={ref} {...settings}>
            {reviewes.map((review) => {
              return <ReviewCard review={review} />;
            })}
          </Slider>
          <Col className={classes.controlButtons}>
            <ReactSlickController
              next={buttonsController.next}
              prev={buttonsController.prev}
              slickGoTo={buttonsController.slickGoTo}
              activeSlide={activeSlide}
              totalSlides={reviewes.length}
              slidesToShow={1}
            />
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default InternshipCourseReviews;
