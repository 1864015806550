import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { bindActionCreators } from "redux";

import { variants } from "../../../utils/variants";
import { CustomButton } from "../../adaptors/CustomButton";
import {
  cartDetailsActions,
  helperActions,
  modalActions,
} from "../../../utils/redux/actions";
import { ADD_CART_ITEM, RESET_CART } from "../../../../graphql/mutations/cart";
import { useSelector } from "react-redux";
import BuyNowModalContent from "../BuyNowModalContent";
import { CustomModal } from "../../CustomModal";
import { LMS_PLATFORM_OUT_LINK } from "../../../utils/localization";

const InternshipBuyNowButton = (props) => {
  const { course, customClass, customTextClass, userSubscriptionStatus } =
    props;

  const { cartDetails, modals, userCourses } = useSelector((state) => {
    return state;
  });

  const [buttonTitle, setButtonTitle] = useState("");

  const dispatch = useDispatch();
  const [addItemToCart] = useMutation(ADD_CART_ITEM);
  const [resetCart] = useMutation(RESET_CART);

  const { setCartDetails } = bindActionCreators(cartDetailsActions, dispatch);
  const { openToastMessage } = bindActionCreators(helperActions, dispatch);
  const {
    openInternshipCoursePurchaseModal,
    closeInternshipCoursePurchaseModal,
  } = bindActionCreators(modalActions, dispatch);

  const handleBuyNow = async (userSubscriptionStatus) => {
    if (userSubscriptionStatus) {
      return (window.location.href = `${LMS_PLATFORM_OUT_LINK}courses/${course.slug}`);
    }
    try {
      if (cartDetails?.uuid) {
        await resetCart({
          variables: {
            input: {
              cartUuid: cartDetails.uuid,
            },
          },
        });
      }
      const { data } = await addItemToCart({
        variables: cartDetails?.uuid
          ? {
              itemableType: "course",
              itemableId: course.id,
              cartUuid: cartDetails?.uuid,
            }
          : {
              itemableType: "course",
              itemableId: course.id,
            },
      });
      setCartDetails(data.cartItemAdd);
      openInternshipCoursePurchaseModal();
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) openToastMessage({ variant: variants.error });
    }
  };

  useEffect(() => {
    if (!userCourses.isCoursesLoading) {
      const getButtonTitle = (userSubscriptionStatus) => {
        if (userSubscriptionStatus) {
          return setButtonTitle("GO TO COURSE");
        }
        return setButtonTitle("BUY NOW");
      };
      getButtonTitle(userSubscriptionStatus);
    }
  }, [userSubscriptionStatus, userCourses.isCoursesLoading]);

  return (
    <>
      <CustomButton
        title={buttonTitle}
        variant={variants.primaryButton}
        customClass={customClass}
        customTextClass={customTextClass}
        onClick={() => {
          handleBuyNow(userSubscriptionStatus);
        }}
        loading={userCourses.isCoursesLoading}
      />
      {modals.internshipCoursePurchaseModal && (
        <CustomModal
          visible={modals.internshipCoursePurchaseModal}
          onCancel={closeInternshipCoursePurchaseModal}
          centered={true}
          width="auto"
          maskStyle={{ background: "rgba(0, 0, 0, 0.45)" }}
          title="Purchase Details"
          bodyStyle={{
            padding: "0 20px 20px 20px",
          }}
        >
          <BuyNowModalContent course={course} />
        </CustomModal>
      )}
    </>
  );
};

InternshipBuyNowButton.propTypes = {
  customClass: PropTypes.string,
  customTextClass: PropTypes.string,
  userSubscriptionStatus: PropTypes.bool,
};

export default InternshipBuyNowButton;
