import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useMutation } from "@apollo/client";

import { variants } from "../../../utils/variants";
import { CustomButton } from "../../adaptors/CustomButton";
import {
  cartDetailsActions,
  helperActions,
} from "../../../utils/redux/actions";
import { ADD_CART_ITEM } from "../../../../graphql/mutations/cart";
import classNames from "classnames";
import * as classes from "./IntenshipAddToCartButton.module.scss";

const IntenshipAddToCartButton = (props) => {
  const { course, customClass, customTextClass, userSubscriptionStatus } =
    props;

  const [buttonTitle, setButtonTitle] = useState("");
  const { cartDetails, userCourses } = useSelector((state) => {
    return state;
  });

  const dispatch = useDispatch();
  const [addItemToCart] = useMutation(ADD_CART_ITEM);

  const { setCartDetails } = bindActionCreators(cartDetailsActions, dispatch);
  const { openToastMessage } = bindActionCreators(helperActions, dispatch);

  const checkCourseInCart = (cartDetails, courseId) => {
    const findItemInCart = cartDetails?.cartItems
      ? cartDetails?.cartItems.find(
          (cartItem) => cartItem?.itemable?.id === courseId
        )
      : false;
    return findItemInCart ? true : false;
  };

  const handleAddCart = async (course) => {
    if (userSubscriptionStatus) {
      return (window.location.href = "/");
    } else if (checkCourseInCart(cartDetails, course.id)) {
      return (window.location.href = "/");
    } else {
      try {
        const { data } = await addItemToCart({
          variables: cartDetails?.uuid
            ? {
                itemableType: "course",
                itemableId: course.id,
                cartUuid: cartDetails?.uuid,
              }
            : {
                itemableType: "course",
                itemableId: course.id,
              },
        });
        setCartDetails(data.cartItemAdd);
        setButtonTitle("EXPLORE COURSES");
      } catch (error) {
        const gqlError = error.graphQLErrors[0];
        if (gqlError) openToastMessage({ variant: variants.error });
      }
    }
  };

  useEffect(() => {
    if (!userCourses.isCoursesLoading) {
      const getButtonTitle = (userSubscriptionStatus) => {
        if (
          userSubscriptionStatus ||
          checkCourseInCart(cartDetails, course.id)
        ) {
          return setButtonTitle("EXPLORE COURSES");
        } else {
          return setButtonTitle("ADD TO CART");
        }
      };
      getButtonTitle(userSubscriptionStatus);
    }
  }, [cartDetails, userSubscriptionStatus, userCourses.isCoursesLoading]);

  return (
    <>
      <CustomButton
        title={buttonTitle}
        variant={variants.primaryButton}
        customClass={classNames(
          customClass,
          buttonTitle === "EXPLORE COURSES" && classes.exploreCoursesButton
        )}
        customTextClass={classNames(
          customTextClass,
          buttonTitle === "EXPLORE COURSES" && classes.exploreCoursesText
        )}
        onClick={() => {
          handleAddCart(course);
        }}
        loading={userCourses.isCoursesLoading}
      />
    </>
  );
};

IntenshipAddToCartButton.propTypes = {
  customClass: PropTypes.string,
  customTextClass: PropTypes.string,
  userSubscriptionStatus: PropTypes.bool,
  course: PropTypes.bool,
};

export default IntenshipAddToCartButton;
