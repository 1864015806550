// extracted by mini-css-extract-plugin
export var carrers = "InternshipCourseDetails-module--carrers--e512b";
export var carrersContainer = "InternshipCourseDetails-module--carrersContainer--0905c";
export var courseDetailContainer = "InternshipCourseDetails-module--courseDetailContainer--4f929";
export var courseDetailContentLeft = "InternshipCourseDetails-module--courseDetailContentLeft--5c2de";
export var courseDetailContentRight = "InternshipCourseDetails-module--courseDetailContentRight--139d5";
export var detailItem = "InternshipCourseDetails-module--detailItem--32978";
export var detailList = "InternshipCourseDetails-module--detailList--9a2bb";
export var detailText = "InternshipCourseDetails-module--detailText--cb95a";
export var heading = "InternshipCourseDetails-module--heading--ef280";
export var lmsScrollbar = "InternshipCourseDetails-module--lms-scrollbar--8df01";
export var tickImage = "InternshipCourseDetails-module--tickImage--d92a2";
export var title = "InternshipCourseDetails-module--title--d8f75";
export var wrapper = "InternshipCourseDetails-module--wrapper--3c54b";