// extracted by mini-css-extract-plugin
export var certificateContainer = "InternshipCourseCertificate-module--certificateContainer--f3e63";
export var certificateContent = "InternshipCourseCertificate-module--certificateContent--91199";
export var certificateContentLeft = "InternshipCourseCertificate-module--certificateContentLeft--e0787";
export var certificateContentMiddle = "InternshipCourseCertificate-module--certificateContentMiddle--09d02";
export var certificateContentRight = "InternshipCourseCertificate-module--certificateContentRight--b2373";
export var certificateImageContainer = "InternshipCourseCertificate-module--certificateImageContainer--da261";
export var detailItem = "InternshipCourseCertificate-module--detailItem--b4449";
export var headingDesktop = "InternshipCourseCertificate-module--headingDesktop--58d1d";
export var headingMobile = "InternshipCourseCertificate-module--headingMobile--054bb";
export var lmsScrollbar = "InternshipCourseCertificate-module--lms-scrollbar--646f2";
export var tickIcon = "InternshipCourseCertificate-module--tickIcon--974bf";
export var title = "InternshipCourseCertificate-module--title--3bac0";
export var wrapper = "InternshipCourseCertificate-module--wrapper--00d6a";