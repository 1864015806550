import React, { useEffect, useRef } from "react";
import { Col } from "antd";

import * as classes from "./NSDC.module.scss";
import { HOME_LANDING_PAGE_FILES_PATH } from "../../../utils/localization";
import { CustomButton } from "../../adaptors/CustomButton";
import { Link } from "gatsby";
import { variants } from "../../../utils/variants";
import { ReactSlickController } from "../../adaptors/ReactSlickController";
import { useState } from "react";
import Slider from "react-slick";

const NSDC = () => {
  const ref = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const [buttonsController, setButtonController] = useState({
    next: () => {},
    prev: () => {},
    slickGoTo: () => {},
  });

  const settings = {
    dots: false,
    arrows: false,
    centerMode: false,
    slidesToShow: 1,
    speed: 500,
    rows: 1,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
  };

  useEffect(() => {
    const slickController = { ...ref?.current };
    setButtonController((state) => ({
      ...state,
      next: slickController?.slickNext,
      prev: slickController?.slickPrev,
      slickGoTo: slickController?.slickGoTo,
      slidesToShow: 1,
    }));
  }, [ref]);

  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <Col className={classes.left}>
          <Col className={classes.certificateCousel}>
            <Slider ref={ref} {...settings}>
              <img
                src={`${HOME_LANDING_PAGE_FILES_PATH}/nsdc-certificate.png`}
              />
              <img
                src={`${HOME_LANDING_PAGE_FILES_PATH}/unschool-certificate.png`}
              />
            </Slider>
          </Col>
          <Col className={classes.controlButtons}>
            <ReactSlickController
              next={buttonsController.next}
              prev={buttonsController.prev}
              slickGoTo={buttonsController.slickGoTo}
              activeSlide={activeSlide}
              totalSlides={2}
              slidesToShow={1}
            />
          </Col>
        </Col>
        <Col className={classes.right}>
          <h1>
            National Skill Development Corporation (NSDC) <br />
            <span>Government Approved Programs and Certificates</span>
          </h1>
          <p>
            NSDC Certification is a prestigious recognition for students who
            have completed accredited skill-based training programs. It
            validates their expertise, demonstrating their dedication to
            practical skills.
          </p>
          <p>
            This certification provides a competitive edge in the job market,
            showcasing proficiency and adherence to industry standards. NSDC
            certifications are widely accepted, unlocking rewarding career
            opportunities and personal growth.
          </p>
          <Link
            to="https://blog.unschool.in/nsdc-partnership-with-unschool/"
            target="_blank"
          >
            <CustomButton
              title={"Read More"}
              variant={variants.primaryButton}
              customClass={classes.buttonCustomClass}
              customTextClass={classes.buttonCustomTextClass}
            />
          </Link>
        </Col>
      </Col>
    </Col>
  );
};

export default NSDC;
