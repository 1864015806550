import React from "react";
import { Col, Typography } from "antd";
import PropTypes from "prop-types";

import * as classes from "./InternshipCoursePurchaseCard.module.scss";
import infinityIcon from "../images/infinity.svg";
import certificateIon from "../images/certificate.svg";
import projectIcon from "../images/projects.svg";
import groupIcon from "../images/groups.svg";
import interShipIcon from "../images/internship.svg";
import levelIcon from "../images/level.svg";
import languageIcon from "../images/language.svg";
import communityIon from "../images/community.svg";
import chaptersIcon from "../images/chapters.svg";
import { capitalizeFirstLetter } from "../../../utils/functions";
import InternshipBuyNowButton from "../InternshipBuyNowButton";
import IntenshipAddToCartButton from "../IntenshipAddToCartButton";

const { Title, Paragraph } = Typography;

const InternshipCoursePurchaseCardMobile = (props) => {
  const {
    course,
    userSubscriptionStatus,
    chaptersCountString,
    courseDurationString,
  } = props;

  return (
    <Col className={classes.purchaseContainerMobile}>
      <Col className={classes.purchaseButtonContainer}>
        <Col
          id="skill-course-purchase-button-mobile"
          className={classes.coursePurchasebuttonContainer}
        >
          <Title className={classes.price}>
            {course?.orignalPrice &&
              !isNaN(course?.orignalPrice) &&
              course?.orignalPrice - course?.price > 0 && (
                <span>&nbsp;₹{course?.orignalPrice}&nbsp;</span>
              )}
            ₹{course?.price}
          </Title>
        </Col>
        <IntenshipAddToCartButton
          course={course}
          customClass={classes.coursePurchasebutton}
          customTextClass={classes.coursePurchasebuttonText}
          userSubscriptionStatus={userSubscriptionStatus}
        />
        <Col className={classes.addToCartButton}>
          <InternshipBuyNowButton
            course={course}
            customClass={classes.addToCartButtonClass}
            customTextClass={classes.addToCartButtonTextClass}
            userSubscriptionStatus={userSubscriptionStatus}
          />
        </Col>
      </Col>
      <Col className={classes.courseVideo}>
        <Col className={classes.videoContainer}>
          <iframe
            className={classes.videoIframe}
            src={
              course?.youtubeUrl
                ? course?.youtubeUrl
                : "https://player.vimeo.com/video/818680206?h=e311b4a50a&badge=0&autopause=0&player_id=0&app_id=58479"
            }
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen={true}
            id="iframe"
          />
          <script src="https://player.vimeo.com/api/player.js" />
        </Col>
        <Col className={classes.courseStatsContent}>
          <Title className={classes.title}>This course includes:</Title>
          <Col className={classes.courseStatsItem}>
            <img src={infinityIcon} />
            <Paragraph className={classes.courseStatsText}>
              Lifelong Content Access
            </Paragraph>
          </Col>
          <Col className={classes.courseStatsItem}>
            <img src={certificateIon} />
            <Paragraph className={classes.courseStatsText}>
              {course?.nsdcCertified
                ? "Unschool & NSDC Certification"
                : "Unschool Certification"}
            </Paragraph>
          </Col>
          <Col className={classes.courseStatsItem}>
            <img src={projectIcon} />
            <Paragraph className={classes.courseStatsText}>
              2 Industry Projects
            </Paragraph>
          </Col>
          <Col className={classes.courseStatsItem}>
            <img src={interShipIcon} />
            <Paragraph className={classes.courseStatsText}>
              Case Studies & Internships
            </Paragraph>
          </Col>
          <Col className={classes.courseStatsItem}>
            <img src={groupIcon} />
            <Paragraph className={classes.courseStatsText}>
              Monthly Live Sessions
            </Paragraph>
          </Col>
        </Col>
        <Col className={classes.courseStatsMobile}>
          <Col className={classes.courseStatsItem}>
            <img src={levelIcon} alt="icon" />
            <span>{capitalizeFirstLetter(course?.level)} Level Level</span>
          </Col>
          <Col className={classes.courseStatsItem}>
            <img src={languageIcon} alt="icon" />
            <span>Language: English</span>
          </Col>
          <Col className={classes.courseStatsItem}>
            <img src={communityIon} alt="icon" />
            <span>{course?.totalLearnersCount}+ Learners</span>
          </Col>
          <Col className={classes.courseStatsItem}>
            <img src={chaptersIcon} alt="icon" />
            <span>
              {chaptersCountString}&nbsp; {courseDurationString}
            </span>
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

InternshipCoursePurchaseCardMobile.propTypes = {
  course: PropTypes.object,
  userSubscriptionStatus: PropTypes.string,
  chaptersCountString: PropTypes.string,
  courseDurationString: PropTypes.string,
};

export default InternshipCoursePurchaseCardMobile;
