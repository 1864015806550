import React from "react";
import { Col } from "antd";

import * as classes from "./InternshipCourseReviews.module.scss";
import { useEffect } from "react";

const ReviewCard = (props) => {
  const { review } = props;

  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 991) {
      setInterval(() => {
        const caseStudyItems = document.getElementsByClassName(
          classes.reviewCard
        );
        let maxEleHeight = 0;
        for (let i = 0; i < caseStudyItems.length; i++) {
          if (caseStudyItems[i].offsetHeight > maxEleHeight) {
            maxEleHeight = caseStudyItems[i].offsetHeight;
          }
        }
        for (let i = 0; i < caseStudyItems.length; i++) {
          caseStudyItems[i].style.height = `${maxEleHeight}px`;
        }
      }, 1000);
    }
  }, []);

  return (
    <Col className={classes.reviewCard}>
      <Col className={classes.reviewCardTop}>
        <img src={review.image} />
        <img src={review.companyLogo} />
      </Col>
      <Col className={classes.reviewCardBottom}>
        <h1>{review.name}</h1>
        <p>{review.review}</p>
      </Col>
    </Col>
  );
};

export default ReviewCard;
