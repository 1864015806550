// extracted by mini-css-extract-plugin
export var addToCartButton = "InternshipCoursePurchaseCard-module--addToCartButton--adf14";
export var addToCartButtonClass = "InternshipCoursePurchaseCard-module--addToCartButtonClass--bb73c";
export var addToCartButtonTextClass = "InternshipCoursePurchaseCard-module--addToCartButtonTextClass--f95b3";
export var couponCode = "InternshipCoursePurchaseCard-module--couponCode--e8206";
export var courseDetails = "InternshipCoursePurchaseCard-module--courseDetails--309d2";
export var courseIncludesHeading = "InternshipCoursePurchaseCard-module--courseIncludesHeading--358c4";
export var coursePurchasebutton = "InternshipCoursePurchaseCard-module--coursePurchasebutton--e6964";
export var coursePurchasebuttonContainer = "InternshipCoursePurchaseCard-module--coursePurchasebuttonContainer--e0d9e";
export var coursePurchasebuttonText = "InternshipCoursePurchaseCard-module--coursePurchasebuttonText--f5af8";
export var courseStats = "InternshipCoursePurchaseCard-module--courseStats--33ae8";
export var courseStatsBottom = "InternshipCoursePurchaseCard-module--courseStatsBottom--d7901";
export var courseStatsBottomItem = "InternshipCoursePurchaseCard-module--courseStatsBottomItem--731fe";
export var courseStatsBottomText = "InternshipCoursePurchaseCard-module--courseStatsBottomText--e49e3";
export var courseStatsContent = "InternshipCoursePurchaseCard-module--courseStatsContent--c0bcc";
export var courseStatsItem = "InternshipCoursePurchaseCard-module--courseStatsItem--429ab";
export var courseStatsMobile = "InternshipCoursePurchaseCard-module--courseStatsMobile--f11fc";
export var courseStatsText = "InternshipCoursePurchaseCard-module--courseStatsText--6ef56";
export var courseStatsTop = "InternshipCoursePurchaseCard-module--courseStatsTop--83b82";
export var courseStatsTopItem = "InternshipCoursePurchaseCard-module--courseStatsTopItem--bb0fe";
export var courseStatsTopText = "InternshipCoursePurchaseCard-module--courseStatsTopText--8e2c4";
export var courseVideo = "InternshipCoursePurchaseCard-module--courseVideo--fd356";
export var customButtonClass = "InternshipCoursePurchaseCard-module--customButtonClass--5fb0b";
export var customButtonTextClass = "InternshipCoursePurchaseCard-module--customButtonTextClass--d27a4";
export var customCouponButtonClass = "InternshipCoursePurchaseCard-module--customCouponButtonClass--87967";
export var customCouponButtonTextClass = "InternshipCoursePurchaseCard-module--customCouponButtonTextClass--9d5c5";
export var lmsScrollbar = "InternshipCoursePurchaseCard-module--lms-scrollbar--30ffb";
export var price = "InternshipCoursePurchaseCard-module--price--2d34d";
export var purchaseButton = "InternshipCoursePurchaseCard-module--purchaseButton--353d0";
export var purchaseButtonContainer = "InternshipCoursePurchaseCard-module--purchaseButtonContainer--1b324";
export var purchaseButtonText = "InternshipCoursePurchaseCard-module--purchaseButtonText--fa1d2";
export var purchaseContainerDesktop = "InternshipCoursePurchaseCard-module--purchaseContainerDesktop--97544";
export var purchaseContainerMobile = "InternshipCoursePurchaseCard-module--purchaseContainerMobile--c9a49";
export var title = "InternshipCoursePurchaseCard-module--title--d1ca8";
export var videoContainer = "InternshipCoursePurchaseCard-module--videoContainer--28875";
export var videoIframe = "InternshipCoursePurchaseCard-module--videoIframe--e7a12";