import React, { useState } from "react";
import { Col, Select } from "antd";
import { Form, Input } from "antd";

import * as classes from "./InternshipCourseChapters.module.scss";
import { CustomButton } from "../../adaptors/CustomButton";
import { variants } from "../../../utils/variants";
import { phoneNumberValidator } from "../../../utils/helpers/validations";
import { useForm } from "antd/lib/form/Form";
import { useMutation } from "@apollo/client";
import { CREATE_INTERNSHIP_LEAD } from "../../../../graphql/mutations/lead";
import { bindActionCreators } from "redux";
import { helperActions } from "../../../utils/redux/actions";
import { useDispatch } from "react-redux";

const ProgramForm = (props) => {
  const { course } = props;
  const [form] = useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [saveLeadMutaion] = useMutation(CREATE_INTERNSHIP_LEAD);
  const dispatch = useDispatch();

  const { openToastMessage } = bindActionCreators(helperActions, dispatch);

  const onFinish = (values) => {
    setButtonLoading(true);
    try {
      saveLeadMutaion({
        variables: { ...values, courseId: course.id },
      });
      setFormSubmitted(true);
      setButtonLoading(false);
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) openToastMessage({ variant: variants.error });
      setButtonLoading(false);
    }
  };

  return (
    <Col className={classes.programForm}>
      {course?.nsdcCertified ? (
        <h1 className={classes.heading}>Get NSDC Program Details</h1>
      ) : (
        <h1 className={classes.heading}>Get Program Details</h1>
      )}
      <Form
        name="basic"
        onFinish={formSubmitted ? () => {} : onFinish}
        autoComplete="off"
        layout="vertical"
        className={classes.form}
        form={form}
      >
        <Form.Item
          label="Name"
          name="fullName"
          rules={[{ required: true, message: "Name is required!" }]}
          className={classes.formItem}
        >
          <Input placeholder="Enter your name" type="text" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please provide valid email!",
            },
          ]}
          className={classes.formItem}
        >
          <Input placeholder="Enter your email id" />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          rules={[
            {
              required: true,
              pattern: new RegExp("^0|[1-9]d*$", "i"),
              message: "Please provide valid phone number!",
              validator: phoneNumberValidator,
            },
          ]}
          className={classes.formItem}
        >
          <Input placeholder="Enter your phone number" type="number" />
        </Form.Item>
        <Form.Item
          label="Choose Course"
          name="courseId"
          rules={[{ required: false }]}
          className={classes.formItem}
        >
          <Select
            options={[{ label: course.title, value: course.id }]}
            placeholder="Select Course"
            defaultValue={course.id}
            disabled
          />
        </Form.Item>
        <Form.Item>
          <CustomButton
            title={formSubmitted ? "Submitted" : "Submit"}
            variant={variants.primaryButton}
            fitContent={true}
            customClass={classes.customButtonClass}
            customTextClass={classes.customButtonTextClass}
            htmlType="submit"
            loading={buttonLoading}
          />
        </Form.Item>
        {formSubmitted && (
          <p className={classes.formSubmissionSuccessText}>
            Thank you <span>for your interest in this course</span>, our team
            will get back to you
          </p>
        )}
      </Form>
    </Col>
  );
};

export default ProgramForm;
