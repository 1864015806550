import React from "react";
import { Avatar, Col, Typography } from "antd";
import PropTypes from "prop-types";

import * as classes from "./InternshipCourseCoach.module.scss";
import CoursesNumberIcon from "../images/courses.png";
import StudentsNumberIcon from "../images/community.png";
import RatingsIcon from "../images/starRating.png";

const { Title, Paragraph } = Typography;

const InternshipCourseCoach = (props) => {
  const { coach } = props;

  return (
    <Col className={classes.coachContainer}>
      <Col className={classes.wrapper}>
        <Col className={classes.coachContent}>
          <Title className={classes.heading}>Meet your coach</Title>
          <Col className={classes.coachDetailsDesktop}>
            <Title className={classes.coachName}>
              {coach?.firstName}&nbsp;{coach?.lastName}
            </Title>
            <Paragraph className={classes.coachIntro}>
              {coach?.headline}
            </Paragraph>
          </Col>
          <Col className={classes.courseStats}>
            <Avatar
              src={coach?.profilePicture?.url}
              className={classes.coachImage}
            />
            <ul className={classes.statsList}>
              <li>
                <img src={CoursesNumberIcon} className={classes.statsIcon} />
                <span>4.5 Instructor Ratings</span>
              </li>
              <li>
                <img src={StudentsNumberIcon} className={classes.statsIcon} />
                <span>{coach?.studentsCount} Students</span>
              </li>
              <li>
                <img src={RatingsIcon} className={classes.statsIcon} />
                <span>{coach?.tutoringCourses?.length} Courses</span>
              </li>
            </ul>
          </Col>
          <Col className={classes.coachDetailsMobile}>
            <Title className={classes.coachName}>
              {coach?.firstName}&nbsp;{coach?.lastName}
            </Title>
            <Paragraph className={classes.coachIntro}>
              {coach?.headline}
            </Paragraph>
          </Col>
          <Paragraph className={classes.coachDetails}>{coach?.about}</Paragraph>
        </Col>
      </Col>
    </Col>
  );
};

InternshipCourseCoach.propTypes = {
  coach: PropTypes.object,
};

export default InternshipCourseCoach;
