import React from "react";
import { Col, Typography } from "antd";

import * as classes from "./InternshipCourseCertificate.module.scss";
import tickIcon from "../images/tickIconYellow.svg";
import { INTERNSHIP_COURSE_PAGE_FILES_PATH } from "../../../utils/localization";

const { Title } = Typography;

const certificateDetails = [
  "Learn With Industry Experts",
  "Self-Paced Learning",
  "Case Studies & Live Projects",
];

const InternshipCourseCertificate = (props) => {
  const { course } = props;

  return (
    <Col className={classes.certificateContainer}>
      <Col className={classes.wrapper}>
        <Col className={classes.certificateContent}>
          <Col className={classes.certificateContentLeft}>
            <Title className={classes.headingDesktop}>
              Start
              <br />
              <span>Upskilling</span>
              <br />
              <span>Today!</span>
            </Title>
            <Title className={classes.headingMobile}>
              Start&nbsp;<span>Upskilling</span>
              &nbsp;<span>Today!</span>
            </Title>
          </Col>
          <Col className={classes.certificateContentMiddle}>
            {certificateDetails.map((item) => (
              <Col key={item} className={classes.detailItem}>
                <img
                  src={tickIcon}
                  alt="A dinosaur"
                  className={classes.tickIcon}
                />
                <Title className={classes.title}>{item}</Title>
              </Col>
            ))}
          </Col>
          <Col className={classes.certificateContentRight}>
            <Title className={classes.title}>Your Certificates</Title>
            <Col className={classes.certificateImageContainer}>
              {course?.nsdcCertified ? (
                <>
                  <img
                    src={`${INTERNSHIP_COURSE_PAGE_FILES_PATH}/certificate-internship-course.png`}
                  />
                  <img
                    src={`${INTERNSHIP_COURSE_PAGE_FILES_PATH}/nsdc-certificate.png`}
                  />
                </>
              ) : (
                <img
                  src={`${INTERNSHIP_COURSE_PAGE_FILES_PATH}/certificate-internship-course.png`}
                />
              )}
            </Col>
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default InternshipCourseCertificate;
