import React, { useState } from "react";
import { Col, Typography } from "antd";
import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import * as classes from "./InternshipCourseFaq.module.scss";
import CustomAccordion from "../../adaptors/CustomAccordion";

const { Title, Paragraph } = Typography;

const InternshipCourseFaq = (props) => {
  const { course } = props;

  const [activeKey, setActiveKey] = useState(-1);

  return (
    <Col className={classes.courseFaqContainer}>
      <Col className={classes.courseFaqContent}>
        <Title className={classes.headingMobile}>
          Got A <br /> Question?
        </Title>
        <Col className={classes.courseFaqContentLeft}>
          <Title className={classes.headingDesktop}>
            Got A <br /> Question?
          </Title>
          <Col className={classes.faqImageContainer}>
            <StaticImage
              loading="lazy"
              src="../images/faqImage.png"
              layout="fullWidth"
              placeholder="blurred"
              alt="certificate"
            />
          </Col>
        </Col>
        <Col className={classes.courseFaqContentRight}>
          {course?.nsdcCertified
            ? [
                ...course?.faqs,
                {
                  question: "How can I get the NSDC certificate?",
                  answer:
                    "We have over 30 courses/programs that are NSDC approved. You will receive the NSDC certificate via email once you finish the course/programs successfully. This is only valid if you have pre-purchased the NSDC add-on while buying the course/program.",
                },
              ].map((faq, index) => (
                <CustomAccordion
                  containerClassName={classes.accordianItem}
                  headerClassName={classes.accordianHeader}
                  textClassName={classes.accordianText}
                  headingContainerClassName={classes.headingContainer}
                  iconClassName={classes.iconClassName}
                  key={index}
                  header={faq.question}
                  text={faq.answer}
                  activeKey={activeKey}
                  currentKey={index}
                  setActiveKey={() =>
                    setActiveKey((state) => (state === index ? -1 : index))
                  }
                />
              ))
            : course?.faqs.map((faq, index) => (
                <CustomAccordion
                  containerClassName={classes.accordianItem}
                  headerClassName={classes.accordianHeader}
                  textClassName={classes.accordianText}
                  headingContainerClassName={classes.headingContainer}
                  iconClassName={classes.iconClassName}
                  key={index}
                  header={faq.question}
                  text={faq.answer}
                  activeKey={activeKey}
                  currentKey={index}
                  setActiveKey={() =>
                    setActiveKey((state) => (state === index ? -1 : index))
                  }
                />
              ))}
          <Paragraph className={classes.contactUnschool}>
            Still stuck with your doubt? Reach out to us on support@unschool.in
          </Paragraph>
        </Col>
      </Col>
    </Col>
  );
};

InternshipCourseFaq.propTypes = {
  course: PropTypes.object,
};

export default InternshipCourseFaq;
