// extracted by mini-css-extract-plugin
export var breadcrumbs = "IntershipCourseHero-module--breadcrumbs--2efd1";
export var companies = "IntershipCourseHero-module--companies--7c675";
export var companiesContainer = "IntershipCourseHero-module--companiesContainer--490f4";
export var companiesHeading = "IntershipCourseHero-module--companiesHeading--5df14";
export var companyLogo = "IntershipCourseHero-module--companyLogo--93ed5";
export var courseApprovals = "IntershipCourseHero-module--courseApprovals--df6d8";
export var courseDescription = "IntershipCourseHero-module--courseDescription--e6c71";
export var courseHeroContainer = "IntershipCourseHero-module--courseHeroContainer--96f2f";
export var courseHeroContentLeft = "IntershipCourseHero-module--courseHeroContentLeft--f0500";
export var courseHeroContentRight = "IntershipCourseHero-module--courseHeroContentRight--60161";
export var coursePurchaseContainerDesktop = "IntershipCourseHero-module--coursePurchaseContainerDesktop--cfae3";
export var coursePurchaseContainerMobile = "IntershipCourseHero-module--coursePurchaseContainerMobile--94f5e";
export var courseRatingDesktop = "IntershipCourseHero-module--courseRatingDesktop--f521c";
export var courseRatingMobile = "IntershipCourseHero-module--courseRatingMobile--ae2f0";
export var courseStatsItem = "IntershipCourseHero-module--courseStatsItem--d986f";
export var courseStatsMobile = "IntershipCourseHero-module--courseStatsMobile--5acec";
export var heroTitle = "IntershipCourseHero-module--heroTitle--0c8c0";
export var instructorName = "IntershipCourseHero-module--instructorName--eaa6e";
export var instructorPhoto = "IntershipCourseHero-module--instructorPhoto--4b9af";
export var instructorProfile = "IntershipCourseHero-module--instructorProfile--d712b";
export var lmsScrollbar = "IntershipCourseHero-module--lms-scrollbar--08828";
export var viewMore = "IntershipCourseHero-module--viewMore--f20b3";
export var wrapper = "IntershipCourseHero-module--wrapper--b4bd6";