import React from "react";
import { Col } from "antd";

import * as classes from "./InternshipCourseChapters.module.scss";
import ChapterCard from "./ChapterCard";
import ProgramForm from "./ProgramForm";

const InternshipCourseChapters = (props) => {
  const { course } = props;
  const { chapters } = course;

  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <Col className={classes.content}>
          <Col className={classes.left}>
            <h1>Chapters</h1>
            <Col>
              {chapters?.map((chapter, index) => (
                <ChapterCard chapter={chapter} key={index} />
              ))}
            </Col>
          </Col>
          <Col className={classes.right}>
            <ProgramForm course={course} />
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default InternshipCourseChapters;
