// extracted by mini-css-extract-plugin
export var bill = "InternshipCoursePurchaseSuccessModalContent-module--bill--80636";
export var billAmount = "InternshipCoursePurchaseSuccessModalContent-module--billAmount--46ee2";
export var billContent = "InternshipCoursePurchaseSuccessModalContent-module--billContent--94041";
export var billItem = "InternshipCoursePurchaseSuccessModalContent-module--billItem--6f187";
export var billTitle = "InternshipCoursePurchaseSuccessModalContent-module--billTitle--c3ed3";
export var billTotal = "InternshipCoursePurchaseSuccessModalContent-module--billTotal--c77b3";
export var itemDetail = "InternshipCoursePurchaseSuccessModalContent-module--itemDetail--0d404";
export var lmsScrollbar = "InternshipCoursePurchaseSuccessModalContent-module--lms-scrollbar--916f2";
export var planDetails = "InternshipCoursePurchaseSuccessModalContent-module--planDetails--5babd";
export var planItem = "InternshipCoursePurchaseSuccessModalContent-module--planItem--90079";
export var portalBtn = "InternshipCoursePurchaseSuccessModalContent-module--portalBtn--af2ff";
export var price = "InternshipCoursePurchaseSuccessModalContent-module--price--66efb";
export var purchaseDetails = "InternshipCoursePurchaseSuccessModalContent-module--purchaseDetails--43c07";
export var successDetails = "InternshipCoursePurchaseSuccessModalContent-module--successDetails--9c420";
export var successDetailsInner = "InternshipCoursePurchaseSuccessModalContent-module--successDetailsInner--63e9b";
export var title = "InternshipCoursePurchaseSuccessModalContent-module--title--7bd8a";
export var totalAmount = "InternshipCoursePurchaseSuccessModalContent-module--totalAmount--33194";
export var totalContent = "InternshipCoursePurchaseSuccessModalContent-module--totalContent--52924";