import reviewerImage1 from "./images/reviewer-image-1.png";
import reviewerImage2 from "./images/reviewer-image-2.png";
import reviewerImage5 from "./images/reviewer-image-5.png";
import reviewerImage6 from "./images/reviewer-image-6.png";
import reviewerImage7 from "./images/reviewer-image-7.png";
import reviewerImage8 from "./images/reviewer-image-8.png";
import incrasoftIon from "./images/incraSooft.svg";
import linkedInIcon from "./images/linkedInIcon.svg";
import sportsMintIcon from "./images/sports-mint.png";
import yourStoryIcon from "./images/your-story.png";
import moneyControlIcon from "./images/money-control.png";

export const categories = [
  {
    title: "All Categories",
    value: "mostPopular",
    id: 1,
  },
  {
    title: "Coding",
    value: "coding",
    id: 2,
  },
  {
    title: "Design",
    value: "design",
    id: 3,
  },
  {
    title: "Finance",
    value: "finance",
    id: 4,
  },
  {
    title: "Management",
    value: "management",
    id: 5,
  },
  {
    title: "Marketing",
    value: "marketing",
    id: 6,
  },
  {
    title: "Technology",
    value: "technology",
    id: 7,
  },
];

export const reviewers = [
  {
    id: 1,
    image: reviewerImage1,
    companyLogo: incrasoftIon,
  },
  {
    id: 2,
    image: reviewerImage1,
    companyLogo: incrasoftIon,
  },
  {
    id: 3,
    image: reviewerImage1,
    companyLogo: incrasoftIon,
  },
  {
    id: 4,
    image: reviewerImage1,
    companyLogo: incrasoftIon,
  },
  {
    id: 5,
    image: reviewerImage1,
    companyLogo: incrasoftIon,
  },
  {
    id: 6,
    image: reviewerImage1,
    companyLogo: incrasoftIon,
  },
];

export const reviewes = [
  {
    id: 1,
    image: reviewerImage1,
    companyLogo: linkedInIcon,
    name: "Dulam Adarsh",
    review:
      "I am from an engineering background and only because of Unschool’s beautifully created Digital Marketing course, now I am placed in IncraSoft as a Digital marketing Intern. The minor and major project experience and the Unschool certificate helped me land this internship, I am grateful for the opportunity! Thank you Unschool!",
  },
  {
    id: 2,
    image: reviewerImage2,
    companyLogo: linkedInIcon,
    name: "Pranay Bathija",
    review:
      "I am a business student and I came across Digital Marketing course from Unschool.I simply loved the detailed course and after completing it I got placed in Cambrionics Life Sciences as Digital Marketeer handling their Social media.",
  },
  {
    id: 3,
    image: reviewerImage5,
    companyLogo: linkedInIcon,
    name: "Shalom Varghese",
    review:
      "My experience as sales and marketing team executive with Unschool and completing soft skills course was amazing! I was Unschool Helped me kick starting my career! I got placed as social media manager for Cambrionics Life Sciences.",
  },
  {
    id: 4,
    image: reviewerImage6,
    companyLogo: linkedInIcon,
    name: "Usharani Jayakrishnan",
    review:
      "At the age of 42 i wanted to do a career switch from commerce to data science with the market it holds. I pursued my Data certification from Unschool, and ihave landed an internship with PPO as a data analyst with a stipend of 20k per month.",
  },
  {
    id: 5,
    image: reviewerImage7,
    companyLogo: linkedInIcon,
    name: "Shinny P",
    review:
      "I did my digital marketing course from the comfort of my home with Unschool and that's a wise decison I have taken for my career.",
  },
  {
    id: 6,
    image: reviewerImage8,
    companyLogo: linkedInIcon,
    name: "Jyoti Meena",
    review:
      "My journey with Unschool was astounding. Here's a great opportunity to learn with internships to practice what you learnt.Thank you for patiently listening to us and taking our every doubt and thought seriously.",
  },
];

export const achievements = [
  {
    id: 1,
    image: sportsMintIcon,
    content: "FC Goa announce first of its kind partnership with Unschool.",
  },
  {
    id: 2,
    image: yourStoryIcon,
    content: "FC Goa announce first of its kind partnership with Unschool.",
  },
  {
    id: 3,
    image: moneyControlIcon,
    content: "FC Goa announce first of its kind partnership with Unschool.",
  },
  {
    id: 4,
    image: sportsMintIcon,
    content: "FC Goa announce first of its kind partnership with Unschool.",
  },
  {
    id: 5,
    image: yourStoryIcon,
    content: "FC Goa announce first of its kind partnership with Unschool.",
  },
  {
    id: 6,
    image: moneyControlIcon,
    content: "FC Goa announce first of its kind partnership with Unschool.",
  },
];
